<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <div class="c-avatar c-sidebar-brand-full pt-10 pb-10 pl-7 pr-7" >
          <img
            src="../../public/img/TLIC/oem-logo-m1.png"
            width=auto 
            height="200"
          />
      </div>
      <div class="c-avatar c-sidebar-brand-minimized">
          <img
            src="img/TLIC/oem-logo-minimize-100.png"
            width=auto 
            height="40"
          />
      </div>
    </CSidebarBrand>

    <CRenderFunction v-if="!isAdmin" flat :content-to-render="$options.nav"/>
    <CRenderFunction v-if="isAdmin" flat :content-to-render="$options.navAdmin"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navAdmin from './_navAdmin'
import Api from "@/services/apiServices"

export default {
  name: 'TheSidebar',
  nav,
  navAdmin,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },  
  data () {
    return {
      isAdmin:false
    }
  },
  created() {
    let userRole = localStorage.getItem('Admin')
    switch (userRole) {
      case "super-admin":
        this.isAdmin = true
        break
      case "faculty-admin":
        this.isAdmin = true
        break
      default:
        break
    }
  },
  methods: {
    async checkUserRole(email){
      let eventList = await Api.getEventListByOwner(email)
      if(eventList){
        this.eventList = eventList
        console.log("eventListByOwner(API):",eventList)
      }
    },
  }
}
</script>
