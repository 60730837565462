<template>
  <CHeader class="border-0">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <!-- <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <div class="c-avatar c-sidebar-brand-full">
          <img
            src="img/TLIC/online-exam-black-text.png"
            width=auto
            height="200"
          />
      </div>
    </CHeaderBrand> -->
    <CHeaderNav class="ml-auto border-0">
      <CHeaderNavItem class="px-3 border-0" actived>
         <!-- <CHeaderNavLink> -->
          <a  href="https://www.info.tlic.cmu.ac.th/" style="color:#666666 ;" target="_blank">TLIC Website</a>
        <!-- </CHeaderNavLink> -->
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3 border-0">
        <!-- <CHeaderNavLink > -->
          <a  target="_blank" href="https://docs.tlic.cmu.ac.th/online-exam-manager-documentation/" style="color:#666666 ;">Manual</a>
        <!-- </CHeaderNavLink> -->
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3 border-0">
        <CHeaderNavLink to="/dashboard">
          <a style="color:#666666 ;">Contact us</a>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="bg-purple2" className="user-profile" >
      <CHeaderNavItem style="width: 100%;">
        <CHeaderNavLink to="/dashboard">
          <a style="color: white !important;">{{userName}}</a>
          <TheHeaderDropdownAccnt/>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 meb-0 mr-auto"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  data(){
    return { 
      userName:localStorage.getItem('email')
    }
  },
  components: {
    TheHeaderDropdownAccnt
  },
  created() {
    // console.log("create header");
    // this.$route.meta.label = "Computer hardware design [2/63]";
    // this.$route.name = this.eventName;
    // console.log(this.$route);
  },
  /*data() {
    return {
      items: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Manage',
          href: '#'
        },
        {
          text: 'Library',
          active: true
        }
      ]
    }
  }*/
}
</script>
<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
  .user-profile{
    /* color: blueviolet !important; */
    background-color: linear-gradient(270deg, #765299 -6.19%, #5E50AD 103.53%);
  }
</style>