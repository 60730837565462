<template>
  <CFooter :fixed="false">
    <div>
      Exam Manager v{{version}}
      <span class="ml-1">&copy; {{new Date().getFullYear()}} TLIC, CMU.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a target="blank" href="https:tlic.cmu.ac.th">TLIC</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  data () {
    return {
      version: process.env.VUE_APP_VERSION
    }
  },  
}
</script>
